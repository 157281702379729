@import 'global';

@font-face {
	font-family: 'cera_pro';
	src: url('../font/cera_pro/cera_pro_black_italic-webfont.woff2') format('woff2'),
	url('../font/cera_pro/cera_pro_black_italic-webfont.woff') format('woff');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'cera_pro';
	src: url('../font/cera_pro/cera_pro_black-webfont.woff2') format('woff2'),
	url('../font/cera_pro/cera_pro_black-webfont.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'cera_pro';
	src: url('../font/cera_pro/cera_pro_bold-webfont.woff2') format('woff2'),
	url('../font/cera_pro/cera_pro_bold-webfont.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'cera_pro';
	src: url('../font/cera_pro/cera_pro_light-webfont.woff2') format('woff2'),
	url('../font/cera_pro/cera_pro_light-webfont.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'cera_pro';
	src: url('../font/cera_pro/cera_pro_medium-webfont.woff2') format('woff2'),
	url('../font/cera_pro/cera_pro_medium-webfont.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'cera_pro';
	src: url('../font/cera_pro/cera_pro_regular_italic-webfont.woff2') format('woff2'),
	url('../font/cera_pro/cera_pro_regular_italic-webfont.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'bodonibook_italic';
	src: url('../font/bodoni/bodoni_book_italic-webfont.woff2') format('woff2'),
	url('../font/bodoni/bodoni_book_italic-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;

}

.ff-cera{ font-family:'cera_pro'; }
.ff-bodoni{ font-family: 'bodonibook_italic'; }

.fw-thin{ font-weight: 300; }
.fw-regular{ font-weight: 500; }
.fw-semibold{ font-weight: 600; }
.fw-bold{ font-weight: bold; }
.fw-extrabold{ font-weight: 800; }
.fw-black{ font-weight: 900; }

.fs-uppercase{ text-transform: uppercase; }
.fs-underline{ text-decoration: underline; }
.fs-italic{ font-style: italic; }

.fc-white{	color : $white-color; }

/*génération des taille de polices mobile*/

$mobile_font_size : 35,24,20;

@each $i in $mobile_font_size {

	.fs-#{$i}{
		font-size : get-mobile-vw(1px*$i);
	}
}

/*génération des taille de polices small desktop */
@media screen and (min-width : map-get($grid-breakpoints, lg)) and (max-width : ($desktop_base_size - 1) * 1px ){

	$desktop_font_size : 20;

	@each $i in $desktop_font_size {

		.fs-d-#{$i}{
			font-size : get-desktop-vw(1px*$i);
		}
	}

}

/*génération des taille de polices desktop */
@media screen and (min-width : $desktop_base_size * 1px){

	$desktop_font_size : 20;

	@each $i in $desktop_font_size {

		.fs-d-#{$i}{
			font-size : 1px*$i;
		}
	}

}